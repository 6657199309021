.card {
    width: 900px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
}

.marketType {
    flex: 1;
    background-color: #007BFF;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: bold;
}

.oddsDetails {
    flex: 4;
    display: flex;
    flex-direction: row; /* Change to row to make them next to each other */
    justify-content: space-between;
}

.oddsRow {
    background-color: #007BFF;
    color: white;
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-left: 10px; /* Add some space between odds rows */
}

.oddsRow.selected {
    background-color: orange;
}

.teamName {
    flex: 1;
    text-align: left;
}

.oddsValue {
    flex: 1;
    text-align: right;
    font-weight: bold;
}
