.container {
    width: 100%;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 5px;
    margin: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.placeholder {
    width: 93%;
    background: rgba(255, 255, 255, 0.25);
    margin: 16px;
}

.congratulationsImage {
    width: 100%;
    height: 100%;
}

.redeemLinkContainer {
    width: 93%;
    height: 57px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 16px;
}

.redeem {
    font-family: Roboto;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    margin: 0px 17px;
}

.redeemLink {
    font-family: Roboto;
    font-size: 18px;
    color: #FFFFFF;
    margin: 0px 17px;
}

.redeemBtn {
    position: absolute;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: linear-gradient(0deg, #ED1FFF 0.24%, rgba(237, 31, 255, 0) 139.92%);
    border-radius: 0px 5px 5px 0px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 27px;
    cursor: pointer;
}