.card {
    border: 1px solid black;
    background-color: lightblue;
    margin: 10px 0;
    padding: 10px;
    width: 100%; /* Make it full width */
    max-width: 300px; /* Adjust max-width as needed */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.helperText {
    margin-bottom: 10px;
    font-weight: bold;
}

.cardFooter {
    display: flex;
    justify-content: space-between;
}
