.container {
    display: flex;
    flex-direction: column;
    width: max-content;
    padding: 28px;
}

.title {
    font-family: Roboto;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    text-align: center;
    margin: 0px;
    margin-bottom: 25px;
}

.results {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.answerChoice {
    font-family: Roboto;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    margin: 8px;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4.8px;
    text-transform: uppercase;
    position: relative;
}

.correct {
    background: rgba(5, 255, 197, 0.4);
}

.correctIcon, .incorrectIcon {
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 25px;
}

.incorrectIcon {
    color: #ED1FFF;
}

.correctIcon {
    color: #05FFC5;
}

.feedback {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width:  50%;
    height: 46px;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 5px;
    font-family: Roboto;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    padding: 0px 15px;
    margin: 8px;
}