/* OddsSidebar.module.css */
.sidebar {
    padding: 20px; /* Adjust padding as needed */
    background-color: #164E44; /* Dark teal background */
    border-left: none; /* Remove the left border */
    height: 98%; /* Ensure it spans the entire height */
    width: 100%; /* Ensure it takes up the full width of its container */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    margin: 0; /* Ensure no margin */
}

.selectedBetsHeader {
    font-size: 1.5rem;
    margin-bottom: 10px; /* Added some bottom margin for spacing */
    padding-bottom: 10px; /* Ensure there is spacing below the header */
    white-space: nowrap;
    text-align: center; /* Center align the text */
    color: #FFFFFF;
}

.loading {
    padding: 10px;
    text-align: center;
    font-style: italic;
    color: #999; /* Placeholder styling for loading text */
}

.oddsList {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
}

.oddsList li {
    padding: 8px 0; /* Adjusted padding for list items */
    border-bottom: none; /* Ensure no border for list items */
}

.oddsList li:not(:last-child) {
    border-bottom: 1px solid transparent; /* Transparent border for all but last item */
}

.combinedOdds {
    margin-top: 10px;
    font-size: 18px;
    color: #FFFFFF; /* White text for contrast */
    white-space: nowrap;
    text-align: center;
}

.betPanelContainer {
    display: flex;
    justify-content: center; /* Center the bet panel */
    width: 100%; /* Ensure it takes up the full width of its container */
}

.betPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    border-top: 1px solid #ccc;
    background-color: #3E8277; /* Slightly lighter teal */
    width: 80%; /* Adjust width as needed */
    max-width: 300px; /* Ensure a maximum width */
    white-space: nowrap; /* Ensure text doesn't wrap */
}

.betSizeContainer, .totalPayoutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.betSizeContainer label, .totalPayoutContainer label {
    font-weight: bold;
}

.betSizeContainer input {
    width: 60px;
    text-align: right;
}

.totalPayoutContainer p {
    margin: 0;
    font-size: 1.2em;
}
