.container {
    width: 98%;
    height: 150px;
    margin-bottom: 1%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.time {
    position: absolute;
    top: 13px;
    left: 18px;
    margin: 0px;
    font-family: Roboto;
    font-style: italic;
    font-size: 11px;
    color: #00FFC1;
}

.title {
    font-family: Roboto;
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
}

.playerContent {
    display: flex;
    flex-direction: row;
}

.playerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.playerImage {
    width: 50px;
    height: 50px;
    background: rgba(196, 196, 196, 0.13);
    border: 0.5px solid #959595;
    border-radius: 50%;
}

.playerName {
    font-family: Roboto;
    font-weight: 500;
    font-size: 7px;
    color: #FFFFFF;
}

.vs {
    font-family: Roboto;
    font-weight: 500;
    font-size: 9px;
    color: #00AC82;
    margin: 20px;
}