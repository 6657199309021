.container {
    position: relative;
    width: 100%;  
}

.text {
    color: #FFFFFF;
    position: relative;
    z-index: 10;
}

.overlay {
    position: absolute;
    top: 0;
    left: 3px;
    width: 100%;
    background: linear-gradient(180deg, #00FFC4 -94.55%, #ED1FFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}