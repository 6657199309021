.container {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 61px);
    background: radial-gradient(51.6% 113.13% at 27.05% 11.81%, #18806D 0%, rgba(14, 40, 100, 0.18) 100%, rgba(15, 44, 100, 0.215763) 100%), radial-gradient(62.91% 116.29% at 58.93% 135.48%, rgba(237, 31, 255, 0.28) 0%, rgba(5, 27, 46, 0) 100%), #051B2E;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    margin-top: 30px;
}

.title {
    font-family: Montserrat, Roboto;
    font-style: italic;
    font-weight: 800;
    font-size: 36px;
    color: #FFFFFF;
    text-align: center;
    margin: 0;
    margin-bottom: 30px;
}