.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    min-height: calc(100vh - 61px);
    background: #051B2E;
}

.content {
    width: 90%;
    margin: 30px 0px;
}