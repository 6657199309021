.container {
    width: 200px;
    height: 200px;
}

.baseTimer {
    transform: scaleX(-1);
}

.circle {
    fill: none;
    stroke: none;
}

.pathElapsed {
    stroke-width: 7px;
    stroke: rgba(255, 255, 255, 0.1);
}

.pathRemaining{
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: url(#MyGradient);
}


.timerText {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scaleX(-1);
}

.countdownText {
    font-family: Montserrat, Roboto;
    font-weight: bold;
    font-size: 36px;
    color: #FFFFFF;
    margin: 0px;
}

.timeRemaining {
    font-family: Roboto;
    font-size: 12px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    margin: 0px;
}