.container {
    background: #1c2d3f;
    width: 100%;
}

.title {
    margin: 0;
    padding: 20px;
    font-family: Roboto;
    font-size: 15px;
    width: 100%;
    color: rgba(255, 255, 255, 0.4);
}

.gameContainer {
    margin-left: 20px;
    flex-direction: row;
    display: flex;
    align-items: center; 
    padding: 10px 0;
}

.gameIcon {
    width: 30px;
    height: 30px;
    background-color: #bbb;
    border-radius: 50%;
    background: rgba(196, 196, 196, 0.13);
    border: 1px solid #959595;
    display: flex;
    justify-content: space-between;
}

.gameName{
    color:white;
    margin: 0;
    margin-left: 20px;
    position: relative;
}