.bodyContainer {
    position: relative;
    background: #051B2E;
    display: flex;
    flex-direction: column;
}

.titleText {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 800;
    font-size: 36px;
    color: white;
    text-align: center;
    margin: 0;
}

.titleSubheader {
    font-family: Roboto;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
}

.stepsContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.lineContainer {
    display: flex;
}

.lineOne {
    border: 7px solid #05FFC5;
    width: 320.98px;
    margin-left: 0px;
}

.lineTwo {
    border: 7px solid #05FFC5;
    width: 201px;
    margin-left: 0px;
}

.lineThree {
    border: 7px solid #05FFC5;
    width: 130px;
    margin-left: 0px;
}

.lineFour {
    border: 7px solid #05FFC5;
    width: 130px;
    margin-right: 0px;
}

.lineFive {
    border: 7px solid #05FFC5;
    width: 320.98px;
    margin-right: 0px;
}

.lineSix {
    border: 7px solid #05FFC5;
    width: 201px;
    margin-right: 0px;
}

.lineSeven {
    border: 7px solid #05FFC5;
    width: 130px;
    margin-left: 0px;
    margin-bottom: 20px;
}

.videoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.icon {
    background-image: url("../../assets/Arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.description {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
}

.tutorial {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.0025em;
    margin: 0;
    margin-right: 30px;
    margin-top: auto;
    margin-bottom: auto;
    color: rgba(255, 255, 255, 0.82);
}

.redirectTutorial {
    display: flex;
}

.bottomContainer {
    background: rgba(255, 255, 255, 0.03);
    width: 100%;
}

.bottomContainerContent {
    width: 80%;
    margin-bottom: 70px;
    margin-top: 70px;
    margin-left: 140px;
}

.walkthroughTitleText {
    font-family: Roboto;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    margin: 0;
    color: #FFFFFF;
}