.container {
    width: 100%;
    height: 251px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 5px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.icon {
    margin: 17px;
    font-size: 18px;
    color: white;
    cursor: pointer;
}