.content {
    display: flex;
    margin: 0 auto;
    width:  75%;
    background: #34373E;
    border-radius: 20px;
    position: relative;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    font-size: 24px;
    cursor: pointer;
    margin: 0;
}