.page {
    display: flex;
    flex-direction: row;
}

.container {
    display: flex;
    justify-content: center;
    background: radial-gradient(113.38% 51.53% at top left, #18806D 0%, rgba(14, 40, 100, 0.18) 100%), radial-gradient(106.06% 47.35% at bottom right, rgba(237, 31, 255, 0.28) 0%, rgba(5, 27, 46, 0) 100%), linear-gradient(140deg, #18806D, #0E2864 50%);
    flex: 1;
    padding-bottom: 30px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-top: 30px;
}

.title {
    font-family: Montserrat, Roboto;
    font-style: italic;
    font-weight: 800;
    font-size: 36px;
    color: #FFFFFF;
    text-align: center;
    margin: 0;
    margin-bottom: 30px;
}

.toggleFeatureContainer {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 5px;
    width: 90%;
    height: 80px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    border: none;
}

.reviewQuestionsContainer {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 5px;
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: auto;
    
}

.dropDownArrow {
    transform: rotate(180deg);
    font-size: 40px;
    font-display: bold;
    margin-top: 10px;
    margin-bottom: auto;
    margin-left: 10px;
    box-sizing: inherit;
}

.dropDownTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    box-sizing: inherit;


    color: #FFFFFF;
}

.submitButton {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 10px;
    /* button */
    background: linear-gradient(179.29deg, rgba(237, 31, 255, 0) -106.4%, #ED1FFF 99.39%);
    border-radius: 4px;
    padding: 10px 10px 10px 10px;


    /* font */
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Global/09. White */

    color: #FFFFFF;
}