.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgba(255, 255, 255, 0.03);
    margin: 1px;
    margin-top: 5px;
}

.title {
    font-family: Montserrat, Roboto;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    margin: 13px 25px;
}

.subtitle {
    font-family: Roboto;
    font-size: 12px;
    color: #FFFFFF;
    margin: 0px 25px;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2.5%;
}

.left {
    width: 45%;
    height: 309px;
    margin-right: 1%;
    margin-left: 4%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
}

.right {
    width: 45%;
    margin-right: 4%;
    margin-left: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}