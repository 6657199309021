.verticalNavContainer {
    width: 61px;
    background: #03121F;
    display: flex;
    flex-direction: column;
}

.iconContainer, .activeIconContainer {
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activeIconContainer {
    width: 58px;
    border-left: 3px solid #00AC82;
    background-color: #041828;
}

.icon {
    color: #FFFFFF;
    width: 20px;
    height: 20px;
}

.activeIconContainer .icon {
    color: #00AC82;
}