.container {
    width: 100%;
    height: 312px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 5px;
    margin: 3px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.rounds {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 36px;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 5px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
}