.container {
    display: flex;
    width: 100%;
    position: relative;
    border-radius: 20px 20px 20px 20px;
    background: url('../../../assets/link.svg');
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;

    border-radius: 20px 0px 0px 20px;
    /* background: radial-gradient(113.38% 51.53% at top left, #18806D 0%, rgba(14, 40, 100, 0.18) 100%), radial-gradient(106.06% 47.35% at bottom right, rgba(237, 31, 255, 0.28) 0%, rgba(5, 27, 46, 0) 100%), linear-gradient(140deg, #18806D, #0E2864 50%); */
}

.right {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    background: #0C2234;
}

.rightSideContent {
    margin-top: 15px;
    margin-bottom: 30px;
    width: 80%;
    box-sizing: border-box;
}

.leftSideContent {
    position: relative;
    width: 70%;
    height: 100%;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: 5%;
    margin-top: 5%;
}

.bottomOfLeftSide {
    width: 100%;
    position: absolute;
    box-sizing: inherit;
    bottom: 2%;
}

.rimbleSignInContainer {
    display: flex;
    flex-direction: row;
    box-sizing: inherit;
    width: 100%;
}

.redirectLink {
    color: #ED1FFF;
    text-decoration: none;
}


.title {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 800;
    font-size: 36px;
    color: #FFFFFF;
    margin: 0;
}

.rimbleLogo {
    width: 30%;
}

.input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    border: 0;
    padding: 12px;
    width: 100%;
    box-sizing: inherit;
    background-color: rgba(234, 234, 234, 0.1);
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.005em;
    color: rgba(255, 255, 255, 0.4);
}

.accountSignInText {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin-left: auto;
    color: #FFFFFF;
}

.inputContainers {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}

.nameContainer {
    display: flex;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}

.firstNameContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    box-sizing: border-box;
    width: 45%;
}

.lastNameContainer {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    box-sizing: border-box;
    width: 45%;
}

.firstName {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    border: 0;
    padding: 12px;
    width: 100%;
    box-sizing: inherit;
    background-color: rgba(234, 234, 234, 0.1);
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.005em;
    color: rgba(255, 255, 255, 0.4);
}

.lastName {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    border: 0;
    padding: 12px;
    width: 100%;
    box-sizing: inherit;
    background-color: rgba(234, 234, 234, 0.1);
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.005em;
    color: rgba(255, 255, 255, 0.4);
}

.termsContainer {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
}

.termsCheckbox {
    margin-right: 10px;
    cursor: pointer;
    background: #05FFC5;
}

.termsText {
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.actionBtn {
    padding: 8px 16px;
    background: linear-gradient(180deg, rgba(240, 31, 255, 0) -151.09%, #F01FFF 100%);
    border-radius: 4.8px;
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.header {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.005em;
    color: #FFFFFF;
    margin-bottom: 6px;
    margin-top: 15px;
}
