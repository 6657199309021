.headerContainer {
    position: relative;
    background-color:  #051B2E;
    background-image: url("../../../assets/homeBackground.png"), linear-gradient(270deg, #18806D 0%, rgba(14, 40, 100, 0.18) 137%);
    display: flex;
    flex-direction: column;
}

.topContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 55%;
    padding-top: 120px;
}

.lineOne {
    border: 7px solid #05FFC5;
    width: 154px;
    margin: 8px 0px;
}

.lineTwo {
    border: 7px solid #05FFC5;
    width: 201px;
    margin: 8px 0px;
}

.titleText {
    font-family: Montserrat;
    font-weight: 800;
    font-size: 100px;
    color: #FFFFFF;
    text-align: left;
    margin-left: 50px;
    margin-top: 45px;
}

.subtitle {
    font-family: Roboto;
    font-size: 36px;
    color: #FFFFFF;
    margin: 10px 58px;
}

.streamBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(240, 31, 255, 0) -151.09%, #F01FFF 100%);
    border-radius: 4.8px;
    width: 124px;
    height: 46px;
    font-family: Roboto;
    font-size: 20px;
    color: white;
    margin: 19px 58px;
    cursor: pointer;
    text-decoration: none;
}

.bottomContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    height: 700px;
    width: 100%;
    padding-bottom: 200px;
}

.fortniteImg {
    width: 58%;
}

.bottomRightContainer {
    width: 22%;
    margin: 18px 55px;
}

.description {
    font-family: Roboto;
    font-size: 24px;
    color: #FFFFFF;
}

.learnBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(240, 31, 255, 0) -151.09%, #F01FFF 100%);
    border-radius: 5px;
    width: 147px;
    height: 46px;
    font-family: Roboto;
    font-size: 20px;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}