.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
}

.question {
    font-family: Roboto;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
}

.userInputContainer {
    flex-direction: row;
    align-items: center;
    display: flex;
    width: 100%;
    min-width: 763px;
}

.answerContainer {
    display: flex;
    flex-direction: row;
}

.answerChoice {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 5px;
    font-family: Roboto;
    font-size: 20px;
    color: #FFFFFF;
    margin: 8px;
    padding: 8px 16px;
}

.inputContainer {
    display: flex;
    flex: 1;
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 5px;
    margin: 8px;
}

.input {
    display: flex;
    width: 100%;
    padding: 8px 16px;
    background: transparent;
    border: 0px;
    outline: none;
    font-family: Roboto;
    font-size: 12px;
    color: #FFFFFF;
}

.input::placeholder {
    font-family: Roboto;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
}