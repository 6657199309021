.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

button:hover {
    background-color: lightgray;
}

.activeTab {
    background-color: blue;
    color: white;
}

.oddsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
