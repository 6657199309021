.bodyContainer {
    background-color: #051B2E;
}

.headingContainer {
    margin: 0 117px;
}

.titleContainer {
    width: 100%;
    height: 125px;
    position: relative;
}

.title {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 72px;
    color: #FFFFFF;
    margin: 0;
}

.lineOne {
    position: absolute;
    top: 48px;
    left: 750px;
    border: 7px solid #05FFC5;
    width: 102px;
    height: 0px;
}

.lineTwo {
    position: absolute;
    left: 555px;
    bottom: 0px;
    border: 7px solid #05FFC5;
    width: 257px;
}

.subtitle {
    font-family: Roboto;
    font-size: 24px;
    color: #FFFFFF;
}

.prizeContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 103px;
    margin-bottom: 236px;
}

.prize {
    width: 284px;
    height: 232px;
    margin: 0 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.03);
    font-family: Roboto;
    font-size: 24px;
    color: #FFFFFF;
}

.bottomContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 200px;
}

.fortniteImg {
    width: 58%;
    height: 551px;
}

.bottomLeftContainer {
    width: 22%;
    margin: 18px 55px;
}

.description {
    font-family: Roboto;
    font-size: 24px;
    color: #FFFFFF;
}

.streamsBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(240, 31, 255, 0) -151%, #F01FFF 100%);
    border-radius: 5px;
    width: 147px;
    height: 46px;
    font-family: Roboto;
    font-size: 20px;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}