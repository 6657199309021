.gameContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 61px);
    position: relative;
}

.contentContainer {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
}

.middleContent {
    width: 100%;
    flex: 1; /* This will take up twice as much space as the sidebar */
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden; /* Prevent overflow */
}

.video-js {
    width: 100%; /* Ensure video takes full width available */
    height: auto; /* Maintain aspect ratio */
}

.popUpQuestion {
    position: absolute;
    top: 6px;
    right: 20px;
}

.oddsSidebar {
  width: auto;
  height: auto;
}
