.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgba(255, 255, 255, 0.03);
    margin-top: 5px;
}

.title {
    font-family: Montserrat, Roboto;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    margin: 13px 25px;
}

.subtitle {
    font-family: Roboto;
    font-size: 12px;
    color: #FFFFFF;
    margin: 0px 25px;
}

.prizeGrid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 1% 10%;
}

.rewardContainer {
    width: 142px;
    height: 142px;
    border-radius: 9px;
    position: relative;
    margin: 12px;
    cursor: pointer;
}

.uncheckedReward {
    background: rgba(255, 255, 255, 0.08);
}

.checkedReward {
    background: rgba(255, 255, 255, 0.26);
}

.checkMark {
    position: absolute;
    top: 15px;
    right: 15px;
    border-radius: 50%;
    font-size: 20px;
}

.uncheckedReward .checkMark {
    background-color: #5E6D78;
    color: #5E6D78;
}

.checkedReward .checkMark {
    background-color: transparent;
    color: white;
}

.redeemContainer {
    display: flex;
    justify-content: flex-end;
}

.redeemBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    background: linear-gradient(180deg, rgba(240, 31, 255, 0) -151.09%, #F01FFF 100%);
    border-radius: 5px;
    font-family: Roboto;
    font-size: 20px;
    color: #FFFFFF;
    margin: 26px 12%;
    cursor: pointer;
}