.container {
    width: 100%;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px;
}

.title {
    width: calc(100% - 36px);
    display: inline-block;
    font-family: Montserrat, Roboto;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 20px 18px;
}

.trophyContainer {
    display: flex;
    flex-direction: row;
}

.circle {
    width: 151px;
    height: 151px;
    background: rgba(196, 196, 196, 0.3);
    border-radius: 50%;
    margin: 20px 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trophy {
    margin-top: 10px;
}

.confetti {
    position: absolute;
    top: -30px;
    right: -5px;
    width: 221px;
}

.placeholder {
    width: 991px;
    height: 269px;
    background-color: rgba(255, 255, 255, 0.25);
    margin: 20px;
}

.winnerImage {
    width: 100%;
}