.footerContainer {
    height: 344px;
    background: #03121F;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 69px;
    padding-bottom: 20px;
    width: 90%;
}

.title {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 36px;
    color: #FFFFFF;
    margin-right: 90px;
    margin-top: 0;
    margin-bottom: 0;
}

.contactBtn {
    width: 123px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(240, 31, 255, 0) -151%, #F01FFF 100%);
    border-radius: 5px;
    font-family: Roboto;
    font-size: 20px;
    color: #FFFFFF;
    cursor: pointer;
}

.divider {
    width: 90%;
    border: 0.5px solid rgba(255, 255, 255, 0.21);
}

.mainContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 144px;
    width: 90%;
}

.contentTitle {
    font-family: Roboto;
    font-size: 12px;
    color: #FFFFFF;
}

.email {
    font-family: Roboto;
    font-size: 12px;
    text-decoration-line: underline;
    color: #ED1FFF;
}

.contentRight {
    display: flex;
    flex-direction: row;
}

.greyText {
    font-family: Roboto;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.38);
    text-decoration: none;
    margin-bottom: 5px;
}

.linksContainer {
    display: flex;
    flex-direction: column;
    width: 100px;
}

.licensingContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
}

.company {
    font-family: Roboto;
    font-size: 10px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.38);
    margin-right: 201px;
}

.terms {
    font-family: Roboto;
    font-size: 10px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}