.page {
    display: flex;
    min-height: calc(100vh - 61px);
    overflow-x: hidden; /* Prevent horizontal overflow */
    overflow-y: hidden;
    width: 100%; /* Ensure the page takes full width */
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: radial-gradient(51.6% 113.13% at 27.05% 11.81%, #18806D 0%, rgba(14, 40, 100, 0.18) 100%, rgba(15, 44, 100, 0.215763) 100%), radial-gradient(62.91% 116.29% at 58.93% 135.48%, rgba(237, 31, 255, 0.28) 0%, rgba(5, 27, 46, 0) 100%), #051B2E;
    flex: 1;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}

.titleContainer {
    position: relative;
    width: 100%;
}

.title {
    position: relative;
    font-family: Montserrat, Roboto;
    font-style: italic;
    font-weight: 800;
    font-size: 36px;
    color: #FFFFFF;
    z-index: 1;
    text-align: center;
}

.titleShadow {
    position: absolute;
    top: 0px;
    transform: translateX(2px);
    width: 100%;
    text-align: center;
    font-family: Montserrat, Roboto;
    font-style: italic;
    font-weight: 800;
    font-size: 36px;
    background: linear-gradient(180deg, #00FFC4 -95%, #ED1FFF 100%);
    background-clip: text;
    color: transparent;
}

.mainContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.oddsPanel {
    flex: 0 0 70%;
    padding-right: 10px;
}

.oddsSidebar {
    flex: 0 0 30%;
    padding-left: 10px;
}
