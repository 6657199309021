.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.backgroundImg{
    background-image: url("../images/image 1456.png");
    background-size: cover;
    width: 100%;
    height: 300px;
    position: relative;
}

.backgroundImgFade{
    position: absolute;
    bottom: 0;
    opacity: 0.8;
    width: 100%;
    height: 189px;
    background: linear-gradient(180deg, rgba(3, 18, 31, 0) 0%, rgba(3, 18, 31, 0.447917) 18.09%, rgba(3, 18, 31, 0.442708) 27.5%, #03121F 69.46%);
}

.backgroundImgText{
    position: absolute;
    bottom: 35px;
    margin-left: 40px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: #FFFFFF;
}

.backgroundImgSubtext{
    position: absolute;
    bottom: 0;
    margin-left: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.7);
}

.buttonContainer {
    width: 100%;
}

.filter {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    background: #ED1FFF33;
    font-family: Roboto;
    font-size: 20px;
    color: #FFFFFF;
    margin: 20px 10px;
    cursor: pointer;
}

.filter.activeFilter {
    background: linear-gradient(179.29deg, rgba(237, 31, 255, 0) -106.4%, #ED1FFF 99.39%);
}