.container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 20%;
}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #062339;
    width: calc(100% - 24px);
    height: 43px;
    padding: 0px 12px;
}

.closeChatIcon {
    color: white;
    cursor: pointer;
}

.chatBtn, .statsBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    width: 66px;
    height: 40px;
    margin: 0px 14px;
    text-align: center;
    border-bottom: 3px solid transparent;
    cursor: pointer;
}

.chatBtn {
    border-color: #00AC82;
}

.openChatIcon {
    position: absolute;
    width: 28px;
    height: 28px;
    right: 0px;
    top: 136px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #1B232A;
    color: #CCD2E3;
}

.content {
    flex: 1;
    position: relative;
}

.statsContainer {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
}

.oddsContainer {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
}