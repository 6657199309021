.container {
    width: 100%;
    min-height: 194px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1px;
}

.bannerImage {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.85;
    z-index: 0;
}

.userInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex: 1;
    position: relative;
    background-color: rgba(145, 70, 146, 0.19);
}

.profilePictureContainer {
    width: 189px;   
    height: 100%;
    z-index: 2;
}

.profilePicture {
    cursor: pointer;
    background: #DADADA linear-gradient(90deg, #ED1FFF -30%, rgba(5, 255, 197, 0.42) 100%);
    width: 136px;
    height: 136px;
    border-radius: 50%;
    position: relative;
    top: 35px;
    left: 36px;
}

.profilePicture p {
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    margin: 5px;
}

.profilePictureOverlay {
    position: absolute;
    bottom: 0px;
    height: 68px;
    width: 136px;
    border-radius: 0 0 150px 150px;
    background: rgba(5, 27, 46, 0.68);
}

.userInfo {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.username {
    font-family: Montserrat, Roboto;
    font-weight: bold;
    font-size: 36px;
    color: #FFFFFF;
    margin: 7px 0px;
}

.userDetails {
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    margin: 2px 0px;
}

.tabsContainer {
    display: flex;
    flex-direction: row;
    background: rgba(5, 27, 46, 0.38);
    padding-left: 189px;
    z-index: 1;
}

.tabButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    margin: 9px 5px 5px 5px;
    background: rgba(237, 31, 255, 0.2);
    border-radius: 4px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color: white;
    cursor: pointer;
}

.tabButton.activeTab {
    background: linear-gradient(180deg, rgba(237, 31, 255, 0) -106%, #ED1FFF 100%);
}
