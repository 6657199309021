.container {
    display: flex;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
}

.square {
    position: absolute;
    top: 0px;
    left: -25px;
    width: 50px;
    height: 40px;
    background: #05FFC5;
    padding-top: 10px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    color: #051B2E;
}

.title {
    position: absolute;
    font-family: Roboto;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    margin-left: 50px;
    top: -15px;
    color: #FFFFFF;
}

.innerContainer {
    width: 100%;
    height: 280px;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    background: rgba(255, 255, 255, 0.03);
}

.iconContainer {
    display: flex;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 10px;
    margin-right: 10px;
}

.actionText {
    font-family: Roboto;
    font-size: 18px;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 40px;
    color: rgba(255, 255, 255, 0.82);
}

.icon {
    background-image: url("../../../assets/Arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
}
