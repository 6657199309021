.notConnectedContainer, .connectedContainer {
    width: 398px;
    height: 60px;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin: 5px 0px;
}

.connectedContainer {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #FFFFFF;
}

.notConnectedContainer {
    background: rgba(5, 255, 197, 0.14);
    border: 1px solid #05FFC5;
}

.platformContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.name {
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-left: 30px;
}

.connectionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 82px;
    background: linear-gradient(180deg, rgba(237, 31, 255, 0) -37.5%, #ED1FFF 100%);
    border: 1px solid #ED1FFF;
    border-radius: 4px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}