.container {
    width: 100%;
    height: 100%;
    background: #03121F;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.oddsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* height: 138px; */
    margin-top: 1px;
    margin-bottom: 20px;
    max-width: 100%;
    
}

.persistentOddsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* height: 138px; */
    margin-top: 1px;
    margin-bottom: 20px;
    position: relative;
}

.graphContainer {
    width: 90%;
    height: 150px;
    background: #032e4f;
    color: white;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 30px 30px 0 0;
    padding: 20px;
}

.titleText {
    font-family: Roboto;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    /* width: 64px; */
    /* height: 30px; */
    background: #ED1FFF;
    background-clip: text;
    -webkit-text-stroke: 1px transparent;
    margin-left: 20px;
    margin-top: 20px;
    align-self: start;
}

.optionContainer {
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
    justify-content: flex-end;
    width: 100%;
    /* height: 100px; */
}

.optionText {
    /* width: 100px; */
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #3C3575 0%, #563189 50%);
    border: 1px solid #ED1FFF;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    margin: 0 16px;
    cursor: pointer !important;
    z-index: 999;
    padding: 10px;
}

.betOptionText {
    width: 30%;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #3C3575 0%, #563189 50%);
    border: 1px solid #ED1FFF;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    margin: 0 8px;
    cursor: pointer !important;
    z-index: 999;
    padding: 20px;
}

.betContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%
}

.amountText {
    color: #FFFFFF
}

.placeBetContainer {
    display: flex;
    flex-direction: row;
}

.placeBetText {
    height: 25px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #470c4d;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    margin: 0 8px;
    cursor: pointer !important;
    z-index: 999;
    padding: 20px;
}

.cancelBetText {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #470c4d;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    background-color: #dd7070;
    margin: 0 8px;
    cursor: pointer !important;
    z-index: 999;
    padding: 20px;
}