.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: rgba(255, 255, 255, 0.03);
    margin: 1px;
    margin-top: 5px;
}

.left {
    display: flex;
    flex-direction: column;
    margin: 13px 25px;
    width: 65%;
    padding-bottom: 50px;
}

.title {
    font-family: Montserrat, Roboto;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    margin: 0px
}

.subtitle {
    font-family: Roboto;
    font-size: 12px;
    color: #FFFFFF;
    margin: 13px 0px;
}

.right {
    display: flex;
    flex-direction: column;
    margin: 13px 25px;
    width: 35%;
}

.socialSubtitle {
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
}
