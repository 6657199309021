.navContainer {
    width: 100%;
    height: 61px;
    background: #051B2E;
    background-image: linear-gradient(270deg, #18806D 0%, rgba(14, 40, 100, 0.18) 137.09%);
}

.navContainer.dark {
    background: #03121F;
}

.navContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height:  100%;
}

.leftContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 42px;
}

.rimbleLogo {
    width: 98px;
    height: 14px;
}

.navList {
    margin-left: 30px;
}

.navList > a {
    color:  white;
    text-decoration: none;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    margin: 0 25px;
}

.rightContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 42px;
}

.mediaLogo {
    width: 20px;
    height: 14px;
    color: #05FFC5;
    margin: 0 14px;
}

.register {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 79px;
    height: 28px;
    background: rgba(0, 172, 130, 0.25);
    border-radius: 3px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;
    margin: 0 20px;
}

.signIn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 79px;
    height: 28px;
    background: linear-gradient(to right, #9e6beb, #28d7d0);
    border-radius: 3px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;
}

.username {
    color: white;
    text-decoration: none;
    font-weight: bold;
}