.container {
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
    padding: 5px 15px;
    margin-bottom: 10px;
    width: 100%;
    
    /* background: rgba(255, 255, 255, 0.03); */
    border-radius: 5px;
    box-sizing: inherit;
}

.logo {
    margin-right: 15px;
    width: 10%;
    /* justify-content: right; */
}

.text {
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.005em;
    margin: 0;
}