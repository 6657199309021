.playContainer {
    background-color: #051B2E;
    display: flex;
    flex-direction: column;
    padding: 50px;
    min-height: 100vh; /* Ensures the container takes up at least the full viewport height */
    box-sizing: border-box; /* Includes padding in the element's total height and width */
}

.row {
    display: flex;
    flex-direction: row;
    flex-grow: 1; /* Ensures rows grow to fill available space */
}

.left {
    width: 80%;
    margin-right: 20px;
}

.right {
    width: 20%;
}
