.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 13px;
    margin-bottom: 25px;
}

.collapsed {
    background: rgba(255, 255, 255, 0.06);
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.collapseIcon {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0px 25px;
    cursor: pointer;
    font-weight: bold;
    font-size: 24px;
}

.title {
    font-family: Montserrat, Roboto;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.score {
    position: absolute;
    right: 0px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    padding: 6px 12px;
    margin: 12px;
    background: linear-gradient(179.29deg, rgba(237, 31, 255, 0) -106.4%, #ED1FFF 99.39%);
    border: 1px solid #ED1FFF;
    border-radius: 4px;
}

.uncollapsed {
    width: 100%;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 0px 0px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}