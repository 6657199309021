.container {
    display: flex;
    align-items: center;
    background-color: #04284d;
    border-radius: 10px;
    padding: 0 15px 5px 15px;
    margin: 10px;
    flex-direction: column;
    width: 90%;
    /* justify-content: space-between; */
}

/* .scoreContainer,
.categoryContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 33px;
    font-family: Roboto;
    font-size: 12px;
    text-align: center;
    color: white;
    margin: 1px 0.5px;
} */

.categoryContainer {
    width: 90px;
}

.category {
    font-weight: 900;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 80px; */
}



.questionText {
    font-family: Roboto;
    font-weight: bold;
    font-size: 15px;
    color: #FFFFFF;
    /* margin: 15px */
}

.optionContainer {
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
    justify-content: flex-end;
    /* height: 100px; */
}

.optionText {
    width: max-content;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #3C3575 0%, #563189 50%);
    border: 1px solid #ED1FFF;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    margin: 0 16px;
    cursor: pointer !important;
    z-index: 999;
    padding: 10px;
}

.answerText {
    width: max-content;
    height: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    /* margin: 0 8px; */
    cursor: pointer !important;
    z-index: 999;
}

@keyframes shrinkage {
    to {
        width: 0px;
    }
}

.timer {
    height: 10px;
    width: 100%;
    border-radius: 0px 10px 10px 0px;
    display: relative;
    margin-top: 10px;
}

.shrinkingContainer {
    height: 100%;
    width: 100%;
    border-radius: 0px 10px 10px 0px;
    overflow: hidden;
    animation: shrinkage calc(var(--duration) * 1s) linear forwards;
    transform-origin: left center;
}

.shrinkingBackground {
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, #05FFC5, #ED1FFF);
    border-radius: 0px 10px 10px 0px;
}