.container {
    width: 100%;
    height: 61px;
    background: #03121F linear-gradient(270deg, #05FFC5 -136.14%, rgba(14, 40, 100, 0.18) 117.91%);
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 46px;
}

.paginationText {
    font-family: Roboto;
    font-weight: bold;
    font-size: 26px;
    color: #FFFFFF;
    width: 64px;
    height: 30px;
    background: #ED1FFF;
    background-clip: text;
    -webkit-text-stroke: 1px transparent;
    margin-left: 12px;
    margin-right: 12px;
    text-align: center;
}

.questionText {
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.optionText {
    width: 150px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #3C3575 0%, #563189 50%);
    border: 1px solid #ED1FFF;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    margin: 0 16px;
    cursor: pointer !important;
    z-index: 999;
    padding: 15px;
}

@keyframes shrinkage {
    to {
        width: 0px;
    }
}

.timer {
    height: 15px;
    width: 100vw;
    border-radius: 0px 10px 10px 0px;
    display: relative;
}

.shrinkingContainer {
    height: 100%;
    width: 100%;
    border-radius: 0px 10px 10px 0px;
    overflow: hidden;
    animation: shrinkage calc(var(--duration) * 1s) linear forwards;
    transform-origin: left center;
}

.shrinkingBackground {
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, #05FFC5, #ED1FFF);
    border-radius: 0px 10px 10px 0px;
}