.betPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    border-top: 1px solid #ccc;
    background-color: lightgreen; /* Light green background */
    width: 100%; /* Increase width */
    white-space: nowrap; /* Ensure text doesn't wrap */
}

.betSizeContainer, .totalPayoutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.betSizeContainer label, .totalPayoutContainer label {
    font-weight: bold;
}

.betSizeContainer input {
    width: 60px;
    text-align: right;
}

.totalPayoutContainer p {
    margin: 0;
    font-size: 1.2em;
}
