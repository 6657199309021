.container {
    display: flex;
    flex-direction: row;
    margin: 4px;
}

.leftContainer {
    height: 100%;
    margin: 4px 24px;
    color: #05FFC5;
}

.rightContainer {
    display: flex;
    flex-direction: column;
}

.category {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 491px;
}

.title {
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    margin: 0px;
}

.subtitle {
    font-family: Roboto;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    margin: 0px;
}

.inputContainer {
    width: 491px;
    height: 43px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 6px 0px;
}

.input {
    display: flex;
    flex: 1;
    padding: 10px;
    background: transparent;
    border: 0px;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    color: #FFFFFF;
}

.editIcon {
    color: #FFFFFF;
    height: 100%;
    padding: 0px 10px;
}