.row {
    border-top: 5px solid #0f2235;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    position: relative;
}

.leftContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    margin: 20px 30px;
    align-items: flex-start;
}

.rightContainer {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    margin: 20px 30px;
    align-items: flex-start;
}

.liveContainer {
    display: flex;
    align-items: center;
    flex:  0.5;
}

.liveDot {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;;
    background: #ED1FFF;
}

.liveText {
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    color: #ED1FFF;
    margin: 0;
    font-weight: 500;
    letter-spacing: 0.07em;
}

.tournamentText {
    flex: 1;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 0.07em;
}

.oddsActionbtn {
    /* position: absolute;
    top: 0;
    right: -50; */
    margin: 0px 20px;
}

.actionBtn {
    /* position: absolute;
    top: 0;
    right: 0; */
    /* margin: 20px 30px; */
    margin: 0px 20px;
}

.watchBtn {
    background-color: #05FFC5;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    color: black;
    margin: 0;
    padding: 10px 10px;
    cursor: pointer;

}

.pregameQABtn {
    background-color: #ED1FFF;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
    margin: 0;
    padding: 10px 10px;
    cursor: pointer;
}

.pregameOddsBtn {
    background-color: #00008B;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
    margin: 0;
    padding: 10px 10px;
    cursor: pointer;
}


.matchup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.teamText {
    font-family: Roboto;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    width: 100px;
    line-height: 22px;
}

.teamIcon {
    width: 97px;
    height: 97px;
    background-color: #bbb;
    border-radius: 50%;
    background: rgba(196, 196, 196, 0.13);
    border: 1px solid #959595;
}

.vsText {
    margin: 30px;
    font-family: Roboto;
    font-weight: 900;
    font-size: 25px;
    text-align: center;
    color: White;
}

.countdownText {
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0.07em;
    color: white;
}

.startsText {
    color: #05FFC5;
}
