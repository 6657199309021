.container {
    display: flex;
    width: 100%;
    position: relative;
    background: url('../../../assets/link.svg');
    border-radius: 20px 20px 20px 20px;
}

.left {
    width: 35%;
    height: 100%;
    display:flex;
    border-radius: 20px 0px 0px 20px;
}

.right {
    display: flex;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    background: #0C2234;
}

.rightSideContent {
    width: 60%;
    box-sizing: border-box;
    margin-top: 30px;
}

.title {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 800;
    font-size: 36px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    margin-bottom: 80px;
}

#emailLogo {
    background-image: url('../../../assets/email.svg');
    background-position: left center;
    background-position-x: 4%;
    background-repeat: no-repeat;
    text-indent: 20px;
}

input#passwordLogo {
    background-image: url('../../../assets/hide.svg');
    background-position: left center;
    background-position-x: 4%;
    background-repeat: no-repeat;
    text-indent: 20px;
}

.titleShadow {
    position: absolute;
    top: -36px;
    transform: translateX(69px);
    font-family: Montserrat, Roboto;
    font-style: italic;
    font-weight: 800;
    font-size: 36px;
    background: linear-gradient(180deg, #00FFC4 -95%, #ED1FFF 100%);
    background-clip: text;
    color: transparent;
    width: 100%;
}

.input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    outline: none;
    border-radius: 8px;
    border: 0;
    padding: 20px;
    box-sizing: inherit;
    width: 100%;
    background-color: rgba(234, 234, 234, 0.1);
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.005em;
    color: rgba(255, 255, 255, 0.4);
}

.rimbleLogo {
    margin-top: auto;
    margin-bottom: 10%;
    margin-left: 10%;
    width: 30%;
}

.redirectLink {
    color: #ED1FFF;
    text-decoration: none;
}

.loginOther {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #FFFFFF;
}

.logo {
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: center;
}

.otherOptions {
    display: flex;
    flex-direction: row;
}

.forgotPassword {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-decoration-line: underline;
    margin-top: 20px;

    color: #ED1FFF;
}

.termsContainer {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.termsCheckbox {
    margin-right: 10px;
    cursor: pointer;
    background: #05FFC5;
}

.signUp {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    margin-bottom: 30px;

    color: #FFFFFF;
}

.termsText {
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.actionBtn {
    padding: 8px 16px;
    background: linear-gradient(180deg, rgba(240, 31, 255, 0) -151.09%, #F01FFF 100%);
    border-radius: 4.8px;
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.header {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}

.error {
    margin: 0;
    color:  white;
    margin-bottom: 20px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 16px;
    padding: 10px;
    border: 2px solid red;
}